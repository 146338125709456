<template>
  <div class="d-flex flex-column fill-height">
    <v-row v-if="itemsTable.length > 1">
      <v-col>
        <div style="text-align: center;"> Mayor consumo </div>
        <apexcharts
          width="100%"
          height="300"
          type="line"
          :options="chartMayorConsumo.options"
          :series="chartMayorConsumo.series">
        </apexcharts>
      </v-col>
      <v-col>
        <div style="text-align: center;"> Menor consumo </div>
        <apexcharts
          width="100%"
          height="300"
          type="line"
          :options="chartMenorConsumo.options"
          :series="chartMenorConsumo.series">
        </apexcharts>
      </v-col>
    </v-row>
    <div>
      <v-simple-table height="100%" dense fixed-header>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Unidad
              </th>
              <th class="text-left">
                Distancia (Km)
              </th>
              <th class="text-left">
                Kilometros por litro
              </th>
              <th class="text-left">
                Gasolina gastada (Litros)
              </th>
            </tr>
          </thead>
            <tbody>
              <tr
                style="cursor: pointer;"
                :class="{ active: index === selectedIndex }"
                v-for="(item, index) in itemsTable"
                :key="item.id"
              >
                <td>
                  <v-chip
                    dark
                    label
                    small
                  >
                    {{ item.deviceName }}
                  </v-chip>
                </td>
                <td>{{ item.distance }}</td>
                <td>{{ item.kmPerLiter || 'Sin definir' }}</td>
                <td>{{ item.spentFuel.toFixed(1) }}</td>
              </tr>
            </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import _ from 'underscore'
import { mapGetters } from 'vuex'
import Dialog from '@/components/dialog'
import Form from '@/components/form'
import ApiService from '@/util/api.service'
import ExportToExcel from '@/util/json_to_excel.service'
import { periods } from '@/util'

const ol = window.ol

export default {
  name: 'EventsReport',
  components: {
    apexcharts: () => import('vue-apexcharts')
  },
  props: {
    devices: Array,
    groups: Array,
  },
  data() {
    return {
      itemsTable: [],
      selected: null,
      selectedIndex: null,
      filtro: {
        from: new Date(),
        fromHour: '00:00',
        to: new Date(),
        toHour: '23:59'
      },
      devicesObj: this.devices.toObject('id'),
      filtersForm: {
        deviceId: {
          label: 'Unidades',
          type: Array,
          options: this.devices,
          multiple: true,
          optional: true
        },
        'deviceId.$': {
          type: String,
          blackbox: true,
        },
        groupId: {
          label: 'Grupos',
          type: Array,
          options: this.groups,
          multiple: true,
          optional: true
        },
        'groupId.$': {
          type: String,
          blackbox: true,
        },
        periodo: {
          label: 'Periodo',
          type: String,
          options: periods,
          onChange: (val, fields) => {
            fields.from_separator.setVisibility(val === 'custom')
            fields.from.setVisibility(val === 'custom')
            fields.fromHour.setVisibility(val === 'custom')
            fields.to_separator.setVisibility(val === 'custom')
            fields.to.setVisibility(val === 'custom')
            fields.toHour.setVisibility(val === 'custom')
          }
        },
        from_separator: {
          label: 'Desde',
          type: String,
          separator: true,
          optional: true
        },
        from: {
          label: 'Fecha',
          type: Date,
          visibility: false
        },
        fromHour: {
          label: 'Hora (HH:mm)',
          type: String,
          visibility: false,
          time: true
        },
        to_separator: {
          label: 'Hasta',
          type: String,
          separator: true,
          optional: true
        },
        to: {
          label: 'Fecha',
          type: Date,
          visibility: false
        },
        toHour: {
          label: 'Hora (HH:mm)',
          type: String,
          visibility: false,
          time: true
        }
      },
      chartMayorConsumo: {},
      chartMenorConsumo: {},
    }
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
    // keymap() {
    //   return {
    //     up: () => {
    //       if (this.selectedIndex > 0) {
    //         this.selectCoordinate(this.itemsTable[this.selectedIndex - 1])
    //       }
    //     },
    //     down: () => {
    //       if (this.selectedIndex != null && this.selectedIndex + 1 <= this.itemsTable.length) {
    //         this.selectCoordinate(this.itemsTable[this.selectedIndex + 1])
    //       }
    //     }
    //   }
    // }
  },
  mounted() {

  },
  methods: {
    async load() {
      window.VMA.loading(true)
      const data = {
        deviceId: this.filtro.deviceId,
        groupId: this.filtro.groupId,
        ...this.period
      }
      try {
        let itemsTable = await ApiService({
          url: '/reports/summary',
          method: 'get',
          params: data
        })
        itemsTable.forEach((item) => {
          const device = this.devicesObj[item.deviceId]
          item.distance = parseFloat((item.distance / 1000).toFixed(1))
          item.kmPerLiter = device && device.attributes && device.attributes.kmPerLiter ? device.attributes.kmPerLiter : 0
          item.spentFuel = item.kmPerLiter ? parseFloat((item.distance / item.kmPerLiter).toFixed(1)) : 0
        })
        itemsTable = _.sortBy(itemsTable, 'spentFuel')
        const menorConsumo = itemsTable.filter((a) => a.spentFuel > 0).slice(0, 5)
        const mayorConsumo = itemsTable.slice(itemsTable.length - 6, itemsTable.length - 1)
        this.chartMenorConsumo = {
          series: [{
            name: 'series-1',
            data: menorConsumo.map((a) => a.spentFuel)
          }],
          options: {
            chart: {
              id: 'vuechart-example'
            },
            xaxis: {
              categories: menorConsumo.map((a) => a.deviceName)
            }
          }
        }
        this.chartMayorConsumo = {
          series: [{
            name: 'series-1',
            data: mayorConsumo.map((a) => a.spentFuel)
          }],
          options: {
            chart: {
              id: 'vuechart-example'
            },
            xaxis: {
              categories: mayorConsumo.map((a) => a.deviceName)
            }
          }
        }
        this.itemsTable = itemsTable.reverse()
        window.VMA.loading(false)
      } catch (e) {
        console.error(e)
        window.VMA.showError({ title: 'Ocurrió un error al cargar los datos' })
      } finally {
        window.VMA.loading(false)
      }
    },
    filtrar() {
      const form = new Form({
        schema: this.filtersForm,
        item: this.filtro
      })
      const dialog = new Dialog({
        title: 'Filtrar rutas',
        actions: [{
          help: 'Filtrar',
          icon: 'mdi-filter',
          color: 'secondary',
          action: async () => {
            if (form.hasErrors()) {
              return
            }
            const item = form.getItem()
            this.filtro = item
            if (item.periodo !== 'custom') {
              this.period = form.fields.periodo.getOption().getPeriod()
            } else {
              this.period = form.fields.periodo.getOption().getPeriod({
                ..._.pick(item, 'from', 'to', 'fromHour', 'toHour')
              })
            }
            this.load()
            dialog.close()
          }
        }]
      })
      dialog.open()
      dialog.append(form)
    },
    toExcel() {
      ExportToExcel(
        'Resumen de unidades '
          + moment(this.period.from).format('DD-MM-YYYY HH mm') + ' a '
          + moment(this.period.to).format('DD-MM-YYYY HH mm'),
        this.itemsTable.map((item) => ({
          Unidad: item.deviceName,
          'Velocidad Promedio': item.averageSpeed,
          Distancia: item.distance,
          'Odometro Inicial': item.startOdometer,
          'Odometro Final': item.endOdometer,
          'Velocidad Máxima': item.maxSpeed,
          'Gasolina Gastada': item.spentFuel,
          'Horas de Motor': item.engineHours
        }))
      )
    },
  }
}

</script>

<style lang="scss">
  .map {
    height: 100%;
    width: 100%;
  }
</style>
